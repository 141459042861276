import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Hero = ({data}) => {
  const { t } = useTranslation();
  const [isMobileVersion, setIsMobileVersion] = useState(false)
  const {descPosition} = data
   useEffect(() => {
      const updateSections = () => {
        if (window.innerWidth <= 768) {
          setIsMobileVersion(true)
        } else {
          setIsMobileVersion(false)
        }
      };
  
      updateSections(); // Initial check
      window.addEventListener('resize', updateSections);
  
      return () => {
        window.removeEventListener('resize', updateSections);
      };
    }, []);
  return (
    <div id='home' className="hero-section text-white">
      <Container className='h-100' fuild>
        <div className="row h-100">
          <div className="h-100">
            <div className="d-flex align-items-start pt-5 pt-md-0 flex-column justify-content-end h-100 pb-xl-4 pb-lg-1 pb-0">
              <h1 className="fs-2 text-uppercase mt-5 mt-md-0">{t('hero.welcome')}</h1>
              {/* <p className='fs-4 text-start'>{t('hero.description')}</p> */}
              <p className={`fs-4 ${descPosition}`}>{t( isMobileVersion ? 'hero.mbldescription' : 'hero.description')}</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;