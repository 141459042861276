import React from 'react';
import ContactSection from './components/ContactSection';
import NestedPageHero from './components/ui/NestedPageHero';
import { ContactMap } from './components/ui/ContactMap';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ContactPage() {
    return (
        <>
            <NestedPageHero title={'Contact Us'} />
            <Container className="my-5 my-3">
                <Card className="bg-dark text-white">
                    <Card.Body>
                        <Row>
                            <Col xs={4}>
                                <Card.Title>Email</Card.Title>
                            </Col>
                            <Col xs={8}>
                                <Card.Text>
                                    <Link to="mailto:info@rzana.co" className="text-white">info@rzana.co</Link>
                                </Card.Text>
                            </Col>
                            <Col xs={4}>
                                <Card.Title>Phone</Card.Title>
                            </Col>
                            <Col xs={8}>
                                <Card.Text>
                                    <Link to="tel:+966556856564" className="text-white">+966 55 685 6564</Link>
                                </Card.Text>
                            </Col>
                            <Col xs={4}>
                                <Card.Title>Address</Card.Title>
                            </Col>
                            <Col xs={8}>
                                <Card.Text className='text-white'>
                                    BBR CO.LTD, 9206 3433 Hamad Al Jaser, Ar Rawdah, Jeddah 23435, Saudi Arabia
                                </Card.Text>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <ContactMap />
            <ContactSection />
        </>
    )
}