import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <div className="d-flex justify-content-center align-items-center">
          <p>&copy; {new Date().getFullYear()} Rzana. {t('footer.rightsReserved')}</p>
          {/* <div>
            <NavLink href="#" className="text-white text-decoration-none">{t('footer.privacyPolicy')}</NavLink>
          </div> */}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;