import React from 'react';
import './Home.css';
import {WhyChose}  from './components/WhyChose';

export default function HandymanLanding() {
  return (
    <div>
    <WhyChose />
    
  </div>
  );
}




