import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; 
export default function NavigationBar() {
  const [scrolled, setScrolled] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedLang, setSelectedLang] = useState('')
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const { t } = useTranslation();
  const switchLanguage = (lng) => {
    setSelectedLang(lng)
    i18n.changeLanguage(lng);
  };
  const sampleData = [
    t('sampleData.transportation'),
    t('sampleData.vision'),
    t('sampleData.experience'),
    t('sampleData.hospitality'),
    t('sampleData.catering'),
    t('sampleData.valetParking'),
    t('sampleData.parkingManagement'),
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 120);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);

    if (query) {
      const results = sampleData.filter((item) =>
        item.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(sampleData);
    }
  };

  const handleResultClick = (result) => {
    setShowSearchInput(false);
    setSearchQuery('');
    setSearchResults([]);

    const section = document.getElementById(result.toLowerCase());
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <motion.div>
      <Navbar
        expand="lg"
        className={`fixed-top navbar-no-padding ${scrolled ? 'bg-white-custom shadow py-0' : ''}`}
        style={{ transition: 'background-color 0.3s ease' }}
      >
        <Container className={`pb-2`}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            {/* <motion.label
              className="text-white d-flex align-items-center gap-2 cursor-pointer"
              onClick={() => setShowOffcanvas(true)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <i className="bi bi-list fs-3"></i>
            </motion.label> */}
            {/* Logo */}
            <Navbar.Brand href="#home">
              <motion.img
                src="assets/img/logo.png"
                alt="logo"
                className="logo"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, ease: 'easeIn' }}
              />
            </Navbar.Brand>
            <div
              className="d-flex align-items-center position-relative"

            >
              {showSearchInput && (
                <motion.input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search Here..."
                  className="search-input border-0 border-bottom p-1 me-2 bg-transparent text-white"
                  style={{
                    outline: 'none',
                    borderBottom: '2px solid #ccc',
                    width: 200,
                  }}
                  initial={{ width: 0, opacity: 0 }}
                  animate={{ width: 200, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  autoFocus
                />
              )}
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: 'spring', stiffness: 300 }}
                onClick={() => setShowSearchInput(!showSearchInput)}
              >

                <Search size={24} className="me-2" />
              </motion.div>
              <div className='ms-2'>
              {
                  selectedLang === 'ar' ?
                  <button className='btn' onClick={() => switchLanguage('en')}>English</button> :
                  <button className='btn' onClick={() => switchLanguage('ar')}>ARABIC</button>
                }
                </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <AnimatePresence>
        {showSearchInput && searchResults.length > 0 && (
          <motion.div
            className="search-results position-fixed bg-white p-2 shadow mt-1"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            style={{
              top: '50px',
              right: '113px',
              width: '200px',
              zIndex: 1050,
            }}
          >
            {searchResults.map((result, index) => (
              <div
                key={index}
                onClick={() => handleResultClick(result)}
                className="text-dark d-block cursor-pointer"
              >
                {result}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showOffcanvas && (
          <Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            placement="start"
            className="bg-white-custom"
            as={motion.div}
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <Offcanvas.Header closeButton className="text-white py-4" />
            <Offcanvas.Body>
              <Nav className="flex-column text-white text-end pe-5">
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1 * 0.1 }}
                  >
                    <Link
                      to={`/`}
                      className="text-white fs-3 text-decoration-none mb-4"
                    >
                       {t('navigation.home')}
                    </Link>
                  </motion.div>
                {sampleData.map((item, index) => (
                  <motion.div
                    key={item}
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <a
                      href={`#${item.toLowerCase()}`}
                      id={item.toLowerCase()}
                      className="text-white fs-3 text-decoration-none mb-4"
                    >
                      {item}
                    </a>
                  </motion.div>
                ))}
                <motion.div
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 7 * 0.1 }}
                  >
                    <Link
                      to={`/contact`}
                      className="text-white fs-3 text-decoration-none mb-4"
                    >
                      {t('navigation.contactUs')}
                    </Link>
                  </motion.div>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
