import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-column' style={{marginTop:'80px', height:'70vh'}}>
        <h1>Page Not Found</h1>
        <p>This Page is not avalible please go back to Home page.</p>
        <Link to='/' className='btn btn-outline-light '>Go Back to Home</Link>
    </div>
  )
}

export default NotFound;