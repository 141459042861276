import React, { useEffect, useState } from 'react';
import Hero from './components/Hero';
import './Home.css';
import SingleSection from './components/ui/SingleSection';
import ContactSection from './components/ContactSection';
import { useTranslation } from 'react-i18next';
import ContactUs from './components/ContactUs';
import ContactDetails from './components/ContactDetails';
import NewContact from './components/NewContact';
export default function Home() {
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  
  const [descPosition, setDescPosition] = useState("")
  const [currentSections, setCurrentSections] = useState([]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    setDescPosition(i18n.language === 'ar' ? 'text-end' : 'text-start');
  }, [i18n.language]);


  useEffect(() => {
    const customCursor = document.getElementById('custom-cursor');
    const hoverContainer = document.querySelector('.hover-container');
  
    const updateCursorPosition = (event) => {
      if (customCursor) {
        customCursor.style.top = `${event.clientY}px`;
        customCursor.style.left = `${event.clientX}px`;
      }
    };
  
    const handleMouseMove = (event) => {
      if (window.innerWidth >= 768) {
        updateCursorPosition(event);
        if (hoverContainer && hoverContainer.matches(':hover')) {
          customCursor.classList.add('zoom');
          customCursor.style.zIndex = 9999999;
        } else {
          customCursor.classList.remove('zoom');
        }
      } else {
        if (customCursor) {
          customCursor.style.top = '';
          customCursor.style.left = '';
          customCursor.classList.remove('zoom');
        }
      }
    };
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  const sectionData = [
    {
      id: 'transportation',
      background: 'url(/assets/img/services/chauffeur.jpg), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('transportation.heading'),
        description: t('transportation.description'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'vision',
      background: 'url(/assets/img/services/vision.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('vision.heading'),
        description: t('vision.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'experience',
      background: 'url(/assets/img/services/experience.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('experience.heading'),
        description: t('experience.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'hospitality',
      background: 'url(/assets/img/services/Hospitality.jpg), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('hospitality.heading'),
        description: t('hospitality.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'golf',
      background: 'url(/assets/img/services/Golf.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('golf.heading'),
        description: t('golf.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'valet parking',
      background: 'url(/assets/img/services/valet.png)',
      data: {
        heading: t('valetParking.heading'),
        description: t('valetParking.description'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'parking management',
      background: 'url(/assets/img/services/parking.png), rgba(0 0 0 / 63%)',
      data: {
        heading: t('parkingManagement.heading'),
        description: t('parkingManagement.description'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'madina transportation',
      background: 'url(/assets/img/services/makkah1.jpg), rgba(0 0 0 / 63%)',
      data: {
        heading: t('madinaTransportation.heading'),
        description: t('madinaTransportation.description'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
  ];


  const mblDesc = [
    {
      id: 'transportation',
      background: 'url(/assets/img/services/chauffeur.jpg), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('transportation.heading'),
        description: t('transportation.mbldescription'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'vision',
      background: 'url(/assets/img/services/vision.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('vision.heading'),
        description: t('vision.mbldescription'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'experience',
      background: 'url(/assets/img/services/experience.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('experience.heading'),
        description: t('experience.mbldescription'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'hospitality',
      background: 'url(/assets/img/services/Hospitality.jpg), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('hospitality.heading'),
        description: t('hospitality.mbldescription'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'golf',
      background: 'url(/assets/img/services/Golf.png), rgba(0, 0, 0, 0.42)',
      data: {
        heading: t('golf.heading'),
        description: t('golf.mbldescription'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
    {
      id: 'valet parking',
      background: 'url(/assets/img/services/valet.png)',
      data: {
        heading: t('valetParking.heading'),
        description: t('valetParking.mbldescription'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'parking management',
      background: 'url(/assets/img/services/parking.png), rgba(0 0 0 / 63%)',
      data: {
        heading: t('parkingManagement.heading'),
        description: t('parkingManagement.mbldescription'),
        color: 'white',
        textPosition: 'bottom',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'start',
    },
    {
      id: 'madina transportation',
      background: 'url(/assets/img/services/makkah1.jpg), rgba(0 0 0 / 63%)',
      data: {
        heading: t('madinaTransportation.heading'),
        description: t('madinaTransportation.mbldescription'),
        color: 'white',
        textPosition: 'top',
        descPosition: i18n.language === 'ar' ? 'text-end' : 'text-start'
      },
      align: 'end',
    },
  ];

  useEffect(() => {
    const updateSections = () => {
      if (window.innerWidth <= 768) {
        setCurrentSections(mblDesc);
      } else {
        setCurrentSections(sectionData);
      }
    };

    updateSections(); // Initial check
    window.addEventListener('resize', updateSections);

    return () => {
      window.removeEventListener('resize', updateSections);
    };
  }, [sectionData, mblDesc]);
  return (
    <div>
      <div id='custom-cursor' style={{zIndex: 99999999}}/>
      <Hero data={descPosition} />
      {currentSections.map((section) => (
        <div
          key={section.id}
          className={`bg-custom h-400 d-flex align-items-${section.align}`}
          id={section.id}
          style={{ background: section.background }}
        >
          <SingleSection data={section.data} />
        </div>
      ))}
      <ContactSection />
      <ContactDetails data={descPosition} />
    </div>
  );
}
