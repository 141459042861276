import React, { useEffect, useRef } from 'react';

const AnimatedText = ({ text, color, position }) => {
  const containerRef = useRef(null);

  useEffect(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    },
    { threshold: 0.5 }
  );

  if (containerRef.current) {
    const children = containerRef.current.querySelectorAll('.animated-text');
    children.forEach((child) => observer.observe(child));
  }

  return () => {
    if (containerRef.current) {
      const children = containerRef.current.querySelectorAll('.animated-text');
      children.forEach((child) => observer.unobserve(child));
    }
  };
}, []);


  const lines = text.split('\\n');
  console.log(lines);


  return (
    <div ref={containerRef} className="animated-text-container">
      <div className={`d-flex gap-2 flex-wrap ${position === 'center' ? 'justify-content-center' : ''} flex-md-row`}>
        {lines.map((line, index) => (
          <div
            key={index}
            className={`animated-text ${index % 2 === 0 && line !== "Golf Car" ? 'left' : 'right'}`}
            style={{ color: color }}
          >
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedText;