import React from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const NestedPageHero = ({ title }) => {
    return (
        <div id='page-hero' className='vh-100 w-100 d-flex flex-column justify-content-center align-items-center'>
            <h1 className='fs-1 fw-bold'>{title}</h1>
            <div className="d-flex gap-2 fs-4">
                <Link to={'/'} className='text-white fw-bold text-decoration-none'>Home</Link>
                <div>
                    <BiRightArrowAlt size={20} />
                </div>
                <p>{title}</p>
            </div>
        </div>
    )
}

export default NestedPageHero;