import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion
import { useInView } from 'react-intersection-observer'; // Import Intersection Observer hook
import { Container, Row, Col } from 'react-bootstrap';
import '../.././pages/Home.css';
import { useTranslation } from 'react-i18next';


const ContactDetails = ({data}) => {
    const descPosition = data?.descPosition
      const { t } = useTranslation();
    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 }); // Detect if in view

    return (
        <div style={{ backgroundColor: 'black', padding: '30px 0' }}>
            <Container className="text-white" ref={ref} id="contact-details">
                {/* Contact Details Heading */}
                <motion.div
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={animationVariants}
                    transition={{ duration: 0.6 }}
                    className="text-center mb-3"
                >
                    <h2 className={`fw-bold ${descPosition}`} style={{ color: 'white', marginBottom: '15px' }}>{t('ContactDetails.contactdetails')}</h2>
                </motion.div>

                {/* Contact Details Section */}
                <motion.div
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={animationVariants}
                    transition={{ duration: 0.8 }}
                >
                    <Row className="d-flex align-items-center">
                        {/* Left Section: Phone and Mobile */}
                        <Col md={6} className={`${descPosition} contact-de-1 fs-4`}>
                            <div style={{ marginBottom: '10px' }}>
                                <h5 className="fw-bold" style={{ color: 'white', marginBottom: '5px' }}>{t('ContactDetails.phone')}</h5>
                                <p>
                                    <a
                                        href="tel:+966126778855"
                                        className="text-decoration-none"
                                        style={{ color: 'white',  direction: 'ltr', unicodeBidi: 'bidi-override' }}
                                    >
                                        (+966 12 677 8855)
                                    </a>
                                </p>
                            </div>
                            <div>
                                <h5 className="fw-bold" style={{ color: 'white', marginBottom: '5px' }}>{t('ContactDetails.mobile')}</h5>
                                <p>
                                    <a
                                        href="tel:+966553609303"
                                        className="text-decoration-none"
                                        style={{ color: 'white',  direction: 'ltr', unicodeBidi: 'bidi-override' }}
                                    >
                                        (+966 553 609 303)
                                    </a>
                                </p>
                            </div>


                            <div style={{ marginBottom: '10px' }}>
                                <h5 className="fw-bold" style={{ color: 'white', marginBottom: '5px' }}>{t('ContactDetails.email')}</h5>
                                <p>
                                    <a
                                        href="mailto:Info@bbr.com.sa"
                                        className="text-decoration-none"
                                        style={{ color: 'white' }}
                                    >
                                        Info@bbr.com.sa
                                    </a>
                                </p>
                            </div>
                            <div>
                                <h5 className="fw-bold" style={{ color: 'white', marginBottom: '5px' }}>{t('ContactDetails.address')}</h5>
                                <p style={{ color: 'white', marginBottom: '0px' }}>
                                {t('ContactDetails.mainaddress')}
                                </p>
                            </div>

                        </Col>
                    </Row>
                </motion.div>
            </Container>
        </div>
    );
};

export default ContactDetails;