import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Form as BootstrapForm, Button, Row, Col, Container } from 'react-bootstrap';
import { motion } from 'framer-motion'; // Import Framer Motion
import { useInView } from 'react-intersection-observer'; // Import Intersection Observer hook
import { useTranslation } from 'react-i18next';
import axios from '../../config/server';

const ContactSection = () => {
    const { t } = useTranslation(); // Use translation hook

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required(t('contact.validation.fullName')),
        email: Yup.string().email(t('contact.validation.invalidEmail')).required(t('contact.validation.email')),
        subject: Yup.string().required(t('contact.validation.subject')),
        message: Yup.string().required(t('contact.validation.message')),
    });

    const animationVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
    };

    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 }); // Detect if in view
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await axios.post('admin/contact', values);
            if (response.status === 200) {
                alert('Message sent successfully!');
                resetForm(); // Reset the form on success
            } else {
                alert('Failed to send the message.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setSubmitting(false); // Stop the form submission spinner
        }
    };

    return (
        <Formik
            initialValues={{
                fullName: '',
                email: '',
                subject: '',
                message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, errors, touched }) => (
                <Container className='my-5' ref={ref} id="contact us">
                    <BootstrapForm noValidate onSubmit={handleSubmit}>

                        {/* Full Name and Email */}
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={animationVariants}
                            transition={{ duration: 0.6, delay: 0.2 }}
                        >
                            <Row className="mb-3">
                                <Col md={6}>
                                    <BootstrapForm.Group controlId="formFullName">
                                        <BootstrapForm.Label>{t('contact.labels.fullName')}</BootstrapForm.Label>
                                        <Field
                                            name="fullName"
                                            type="text"
                                            placeholder={t('contact.placeholders.fullName')}
                                            className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="fullName" component="div" className="invalid-feedback" />
                                    </BootstrapForm.Group>
                                </Col>

                                <Col md={6}>
                                    <BootstrapForm.Group controlId="formEmail">
                                        <BootstrapForm.Label>{t('contact.labels.email')}</BootstrapForm.Label>
                                        <Field
                                            name="email"
                                            type="email"
                                            placeholder={t('contact.placeholders.email')}
                                            className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </BootstrapForm.Group>
                                </Col>
                            </Row>
                        </motion.div>

                        {/* Subject */}
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={animationVariants}
                            transition={{ duration: 0.6, delay: 0.4 }}
                        >
                            <Row className="mb-3">
                                <Col>
                                    <BootstrapForm.Group controlId="formSubject">
                                        <BootstrapForm.Label>{t('contact.labels.subject')}</BootstrapForm.Label>
                                        <Field
                                            name="subject"
                                            type="text"
                                            placeholder={t('contact.placeholders.subject')}
                                            className={`form-control ${touched.subject && errors.subject ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                                    </BootstrapForm.Group>
                                </Col>
                            </Row>
                        </motion.div>

                        {/* Message */}
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={animationVariants}
                            transition={{ duration: 0.6, delay: 0.6 }}
                        >
                            <Row className="mb-3">
                                <Col>
                                    <BootstrapForm.Group controlId="formMessage">
                                        <BootstrapForm.Label>{t('contact.labels.message')}</BootstrapForm.Label>
                                        <Field
                                            name="message"
                                            as="textarea"
                                            placeholder={t('contact.placeholders.message')}
                                            rows={4}
                                            className={`form-control ${touched.message && errors.message ? 'is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                    </BootstrapForm.Group>
                                </Col>
                            </Row>
                        </motion.div>

                        {/* Submit Button */}
                        <motion.div
                            initial="hidden"
                            animate={inView ? 'visible' : 'hidden'}
                            variants={animationVariants}
                            transition={{ duration: 0.6, delay: 0.8 }}
                        >
                            <Button type="submit" variant="primary">
                                {t('contact.buttons.submit')}
                            </Button>
                        </motion.div>

                    </BootstrapForm>
                </Container>
            )}
        </Formik>
    );
};

export default ContactSection;
