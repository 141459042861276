import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Initialize i18n
i18n
  .use(HttpApi) // Load translations using HTTP
  .use(LanguageDetector) // Detect the user's language
  .use(initReactI18next) // Connect with React
  .init({
    fallbackLng: 'en', // Default language
    debug: true, // Enable debug mode in development
    supportedLngs: ['en', 'ar'], // Supported languages
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}.json', // Path to translation files
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Language detection methods
      caches: ['cookie'], // Cache the language in cookies
    },
  });

export default i18n;
